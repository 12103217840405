import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

import { colors, fontSizes, space } from './theme'
import cursor from '../images/cursor.png'
import pointer from '../images/pointer.png'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
      }

      body {
        margin: 0;
        font-family: aktiv-grotesk, sans-serif;
        cursor: url(${pointer}), pointer;
        cursor: url(${cursor}), default;
      }

      p {
        line-height: 1.4;
      }

      .hidden {
        visibility: hidden;
      }

      [data-inviewport='scale-in'] {
        transition: 2s;
        transform: scale(0.1);
      }
      [data-inviewport='scale-in'].is-inViewport {
        transform: scale(1);
      }

      [data-inviewport='fade-in-left'] {
        transition: 1.2s;
        transform: translateX(-200px);
        opacity: 0;
      }
      [data-inviewport='fade-in-left'].is-inViewport {
        display: block;
        transform: translateX(0);
        opacity: 1;
      }

      [data-inviewport='fade-in-right'] {
        transition: 1.2s;
        transform: translateX(200px);
        opacity: 0;
      }
      [data-inviewport='fade-in-right'].is-inViewport {
        transform: translateX(0);
        opacity: 1;
      }

      [data-inviewport='fade-in-bottom'] {
        transition: 1.2s;
        transform: translateY(100px);
        opacity: 0;
      }
      [data-inviewport='fade-in-bottom'].is-inViewport {
        transform: translateY(0);
        opacity: 1;
      }

      [data-inviewport='fade-rotate'] {
        transition: 2s;
        opacity: 0;
      }
      [data-inviewport='fade-rotate'].is-inViewport {
        transform: rotate(180deg);
        opacity: 1;
      }

      [data-inviewport='fade-in'] {
        transition: 2s;
        opacity: 0;
      }
      [data-inviewport='fade-in'].is-inViewport {
        opacity: 1;
      }
    `}
  />
)

export const Hero = styled.h1`
  margin-top: ${space[20]};
  margin-bottom: ${space[20]};
  font-size: ${fontSizes['4xl']};
  color: ${colors.darkGrey};
  text-transform: uppercase;
`

export const H1 = styled.h1`
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1em;
  font-size: ${fontSizes['4xl']};
`

export const H2 = styled.h2`
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: ${fontSizes['3xl']};
  color: ${({ color }) => (color ? color : colors.hergaBlue)};
  text-transform: uppercase;
`

export const H3 = styled.h3`
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: ${fontSizes['2xl']};
`

export const Center = styled.div`
  text-align: center;
`

export const Img = styled(GatsbyImage)`
  max-width: 100 %;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.45rem;
`

export const ContainerExtraLarge = styled.div`
  margin: 0 auto;
  max-width: 1800px;
`

export const MainContent = styled.main`
  margin-top: 100px;
  margin-bottom: 40px;
`

export const Text = styled.div`
  color: ${({ color }) => (color ? color : 'inherit')};
  line-height: 1.6;
  font-size: ${fontSizes.lg};
`

export const IntroText = styled.p`
  line-height: 1.6;
  font-size: 1.2rem;
  margin: 0px;
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  grid-auto-flow: row dense;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  & > :nth-of-type(4n - 1) {
    grid-column-start: 2;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const BigSmallColumn = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  grid-auto-flow: row dense;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  & > :nth-of-type(4n - 1) {
    grid-column-start: 4;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const LeftTwoThirds = styled.div`
  display: grid;
  grid-template-columns: 1fr 66%;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const RightTwoThirds = styled.div`
  display: grid;
  grid-template-columns: 66% 1fr;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const AlternatingTwoThirdsGrid = styled.div`
  display: grid;
  grid-template-columns: 66% 1fr;
  grid-auto-flow: row dense;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};

  & > :nth-child(4n - 1) {
    grid-column-start: 2;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const ThreeThirdsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const FourFourthsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const Button = styled.button`
  color: ${({ color }) => (color ? color : 'white')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '0px')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'black'};
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 12px 20px;
  font-size: 0.8125em;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  white-space: normal;
  display: flex;
  justify-content: center;
  width: 200px;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #f6f6f6;
    color: #717171;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
`

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.8125rem 1rem;
  font-size: 0.8125rem;
  border: 1pt solid black;
  border-radius: 0px;
  font-family: aktiv-grotesk, sans-serif;
  text-transform: uppercase;
  background-color: RGBA(255, 255, 255, 0);
`

export const SmallLine = styled.hr`
  margin: 20px auto;
  border-width: 1px 0 0 0;
  width: 50px;
  clear: both;
  border-top: solid #1c1d1d;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.darkGrey};
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
`
